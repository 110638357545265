import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Code Surfer adds code highlighting, code zooming, code scrolling, code focusing, code morphing, and fun to `}<a parentName="p" {...{
        "href": "https://github.com/jxnblk/mdx-deck"
      }}>{`MDX Deck`}</a>{` slides.`}</p>
    <p>{`To create a new project run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm init code-surfer-deck my-deck
cd my-deck
npm start
`}</code></pre>
    <h2>{`Examples`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://advanced-graphql-workshop.netlify.com/"
        }}>{`Formidable's GraphQL Workshop`}</a>{` by `}<a parentName="li" {...{
          "href": "https://twitter.com/_philpl"
        }}>{`Phil Pluckthun`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/pomber/react-conf-2018-hooks-demo"
        }}>{`React Conf 2018 Hooks Demo`}</a></li>
    </ul>
    <h2>{`How to use Code Surfer`}</h2>
    <blockquote>
      <p parentName="blockquote">{`It may help to know how `}<a parentName="p" {...{
          "href": "https://github.com/jxnblk/mdx-deck"
        }}>{`MDX Deck`}</a>{` works first`}</p>
    </blockquote>
    <p>{`To use Code Surfer you need to import it and wrap the code you want to show inside `}<inlineCode parentName="p">{`<CodeSurfer>`}</inlineCode>{` tags (the `}<strong parentName="p">{`empty lines before and after the codeblock are required`}</strong>{`):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`import { CodeSurfer } from "code-surfer"

# Deck Title

---

<CodeSurfer>

\`\`\`js
console.log(1);
console.log(2);
console.log(3);
\`\`\`

</CodeSurfer>
`}</code></pre>
    <p>{`Features:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#focus"
        }}>{`Focus`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#steps"
        }}>{`Steps`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#title-and-subtitle"
        }}>{`Title and Subtitle`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#themes"
        }}>{`Themes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#custom-styles"
        }}>{`Custom Styles`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#languages"
        }}>{`Languages`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#columns"
        }}>{`Columns`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#import-code"
        }}>{`Import Code`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#line-numbers"
        }}>{`Line Numbers`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#diffs"
        }}>{`Diffs`}</a></li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`Here is a live `}<a parentName="p" {...{
          "href": "https://codesurfer.pomb.us/full/"
        }}>{`deck using all the features`}</a>{` (and its `}<a parentName="p" {...{
          "href": "https://raw.githubusercontent.com/pomber/code-surfer/code-surfer-v2/sites/docs/decks/full.mdx"
        }}>{`mdx source`}</a>{`) just in case you prefer to read code instead of docs.`}</p>
    </blockquote>
    <h2>{`Focus`}</h2>
    <p>{`Add a `}<em parentName="p">{`focus string`}</em>{` after the language in the first line of a codeblock to tell Code Surfer what lines and columns you want to focus.`}</p>
    <p>{`Code Surfer will fade out all the code that isn't focused and, if necessary, zoom it out to fit it in the slide.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`<CodeSurfer>

\`\`\`js 1:2,3[8:10]
console.log(1);
console.log(2);
console.log(3);
\`\`\`

</CodeSurfer>
`}</code></pre>
    <p>{`In the example above `}<inlineCode parentName="p">{`1:2,3[8:10]`}</inlineCode>{` means: "focus from the line 1 to line 2 and the columns 8 to 10 from line 3". More examples:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`5:10`}</inlineCode>{` focus lines 5,6,7,8,9 and 10`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`1,3:5,7`}</inlineCode>{` focus lines 1,3,4,5 and 7`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`2[5]`}</inlineCode>{` focus column 5 in line 2`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`2[5:8]`}</inlineCode>{` focus columns 5, 6, 7 and 8 in line 2`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`1,2[1,3:5,7],3`}</inlineCode>{` focus line 1, columns 1, 3, 4, 5 and 7 in line 2 and line 3`}</li>
    </ul>
    <p><em parentName="p">{`Note: In previous versions of CodeSurfer we used tokens instead of columns.`}</em></p>
    <h2>{`Steps`}</h2>
    <p>{`Add more codeblocks to add steps to a Code Surfer slide.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`<CodeSurfer>

\`\`\`js
console.log(1);
console.log(2);
console.log(3);
\`\`\`

\`\`\`js 1
console.log(1);
console.log(2);
console.log(3);
\`\`\`

\`\`\`js
console.log(1);
console.log(2);
console.log(3);
console.log(4);
console.log(5);
\`\`\`

</CodeSurfer>
`}</code></pre>
    <p>{`You can change the focus and/or the code for different steps and Code Surfer will make the transition between the steps: zooming, scrolling, fading in, fading out, adding and removing lines.`}</p>
    <h2>{`Title and Subtitle`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`<CodeSurfer>

\`\`\`js 1 title="Title" subtitle="Look at the first line"
console.log(1);
console.log(2);
console.log(3);
\`\`\`

\`\`\`js 2 title="Title" subtitle="and now the second"
console.log(1);
console.log(2);
console.log(3);
\`\`\`

</CodeSurfer>
`}</code></pre>
    <h2>{`Themes`}</h2>
    <a {...{
      "href": "https://codesurfer.pomb.us/themes/"
    }}><img parentName="a" {...{
        "src": "https://user-images.githubusercontent.com/1911623/66016573-97df9c00-e4ad-11e9-9095-225d5c9b46a8.png",
        "alt": "Code Surfer Themes"
      }}></img></a>
    <p>{`There are many Code Surfer themes available on the `}<a parentName="p" {...{
        "href": "https://github.com/pomber/code-surfer/blob/code-surfer-v2/packs/themes/src/index.ts"
      }}><inlineCode parentName="a">{`@code-surfer/themes`}</inlineCode></a>{` package.`}</p>
    <p>{`You can pass the theme as a prop `}<inlineCode parentName="p">{`<CodeSurfer theme={someTheme}>`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`import { CodeSurfer } from "code-surfer"
import { nightOwl } from "@code-surfer/themes"

<CodeSurfer theme={nightOwl}>

\`\`\`js
console.log(1);
console.log(2);
console.log(3);
\`\`\`

</CodeSurfer>
`}</code></pre>
    <p>{`Or set the theme for the whole deck as any other `}<a parentName="p" {...{
        "href": "https://github.com/jxnblk/mdx-deck#theming"
      }}>{`MDX Deck theme`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`import { CodeSurfer } from "code-surfer"
import { nightOwl } from "@code-surfer/themes"

export const theme = nightOwl

<CodeSurfer>

\`\`\`js
console.log(1);
console.log(2);
console.log(3);
\`\`\`

</CodeSurfer>
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`Exporting the theme like this will also change the text and background colors for slides that aren't using Code Surfer. If you want to keep the colors from a different mdx deck theme you can `}<a parentName="p" {...{
          "href": "https://github.com/jxnblk/mdx-deck/blob/master/docs/theming.md#composing-themes"
        }}>{`compose both themes`}</a>{` together: `}<inlineCode parentName="p">{`export const themes = [codeSurferTheme, mdxDeckTheme]`}</inlineCode></p>
    </blockquote>
    <h2>{`Custom Styles`}</h2>
    <p>{`You can write your own Code Surfer theme and change the style of the code, title and subtitle:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Themes use `}<a parentName="p" {...{
          "href": "https://theme-ui.com/"
        }}>{`Theme UI`}</a>{` internally`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// custom-theme.js
export default {
  colors: {
    background: "#222",
    text: "#ddd",
    primary: "#a66"
  },
  styles: {
    CodeSurfer: {
      pre: {
        color: "text",
        backgroundColor: "background"
      },
      code: {
        color: "text",
        backgroundColor: "background"
      },
      tokens: {
        "comment cdata doctype": {
          fontStyle: "italic"
        },
        "builtin changed keyword punctuation operator tag deleted string attr-value char number inserted": {
          color: "primary"
        },
        "line-number": {
          opacity: 0.8
        }
      },
      title: {
        backgroundColor: "background",
        color: "text"
      },
      subtitle: {
        color: "#d6deeb",
        backgroundColor: "rgba(10,10,10,0.9)"
      },
      unfocused: {
        // only the opacity of unfocused code can be changed
        opacity: 0.1
      }
    }
  }
};
`}</code></pre>
    <p>{`And use it in your deck like any other theme:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`import { CodeSurfer } from "code-surfer"
import customTheme from "./custom-theme"

<CodeSurfer theme={customTheme}>

\`\`\`js
console.log(1);
console.log(2);
console.log(3);
\`\`\`

</CodeSurfer>
`}</code></pre>
    <h2>{`Languages`}</h2>
    <p>{`Code Surfer uses `}<a parentName="p" {...{
        "href": "https://prismjs.com/"
      }}>{`Prism`}</a>{` for parsing different languages, so it supports `}<a parentName="p" {...{
        "href": "https://prismjs.com/#supported-languages"
      }}>{`all the languages supported by Prism`}</a>{`.`}</p>
    <p>{`Most popular languages are supported out of the box, for the rest you need to import them:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`import { CodeSurfer } from "code-surfer"
import "prismjs/components/prism-smalltalk"

<CodeSurfer>

\`\`\`smalltalk
result := a > b
    ifTrue:[ 'greater' ]
    ifFalse:[ 'less or equal' ]
\`\`\`

</CodeSurfer>
`}</code></pre>
    <h2>{`Columns`}</h2>
    <p>{`If you want to show more than one piece of code at the same time, use `}<inlineCode parentName="p">{`<CodeSurferColumns>`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`import { CodeSurferColumns, Step } from "code-surfer"

<CodeSurferColumns>

<Step subtitle="First Step">

\`\`\`js
console.log(1);
console.log(2);
\`\`\`

\`\`\`js
console.log("a");
console.log("b");
\`\`\`

</Step>

<Step subtitle="Second Step">

\`\`\`js 2
console.log(1);
console.log(2);
\`\`\`

\`\`\`js 2
console.log("a");
console.log("b");
\`\`\`

</Step>

</CodeSurferColumns>
`}</code></pre>
    <p>{`Each `}<inlineCode parentName="p">{`<Step>`}</inlineCode>{` can have its own `}<inlineCode parentName="p">{`title`}</inlineCode>{` and `}<inlineCode parentName="p">{`subtitle`}</inlineCode>{`.`}</p>
    <p>{`You can use different themes for each column: `}<inlineCode parentName="p">{`<CodeSurferColumns themes={[nightOwl, ultramin]}>`}</inlineCode>{`. And change the relative size of the columns with `}<inlineCode parentName="p">{`<CodeSurferColumns sizes={[1,3]}>`}</inlineCode>{`.`}</p>
    <p>{`Columns aren't only for code, you can use them for any kind of content:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`import { CodeSurferColumns, Step } from "code-surfer"
import MyComponent from "./my-component.jsx"

<CodeSurferColumns>

<Step>

\`\`\`js
console.log(1);
console.log(2);
\`\`\`

# Some Markdown

</Step>

<Step>

\`\`\`js 2
console.log(1);
console.log(2);
\`\`\`

<MyComponent/>

</Step>

</CodeSurferColumns>
`}</code></pre>
    <h2>{`Import Code`}</h2>
    <p>{`Instead of writing the code inside codeblocks you can import it from a file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`import { CodeSurfer } from "code-surfer"

<CodeSurfer>

\`\`\`js 5:10 file=./my-code.js
\`\`\`

\`\`\`js file=./my-other-code.js
\`\`\`

</CodeSurfer>
`}</code></pre>
    <h2>{`Line Numbers`}</h2>
    <p>{`To show line numbers add the `}<inlineCode parentName="p">{`showNumbers`}</inlineCode>{` flag to the first step:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`import { CodeSurfer } from "code-surfer"

<CodeSurfer>

\`\`\`js showNumbers
console.log(1);
console.log(2);
console.log(3);
\`\`\`

\`\`\`js
console.log(1);
console.log(2);
console.log(4);
\`\`\`

</CodeSurfer>
`}</code></pre>
    <h2>{`Diffs`}</h2>
    <p>{`Codeblocks can also be diffs. This is particularly useful when using empty diffs for code that doesn't change:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`import { CodeSurfer } from "code-surfer"

<CodeSurfer>

\`\`\`js
console.log(1);
console.log(2);
console.log(3);
\`\`\`

\`\`\`diff 1 subtitle="log 1"

\`\`\`

\`\`\`diff 2 subtitle="log 2"

\`\`\`

\`\`\`diff 3 subtitle="log 3"

\`\`\`

</CodeSurfer>
`}</code></pre>
    <h2>{`Related`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/jxnblk/mdx-deck"
        }}>{`MDX Deck`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/jamiebuilds/spectacle-code-slide"
        }}>{`spectacle-code-slide`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/PrismJS/prism"
        }}>{`Prism`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/pomber/create-code-surfer-deck"
        }}>{`create-code-surfer-deck`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/pomber/gatsby-waves"
        }}>{`Gatsby Waves`}</a></li>
    </ul>
    <h2>{`Support Code Surfer`}</h2>
    <p>{`You can help keep this project alive.`}</p>
    <h3>{`Sponsors`}</h3>
    <p>{`Support this project by becoming a sponsor. Your logo will show up here with a link to your website. [`}<a parentName="p" {...{
        "href": "https://opencollective.com/code-surfer#sponsor"
      }}>{`Become a sponsor`}</a>{`]`}</p>
    <a href="https://opencollective.com/code-surfer/sponsor/0/website" target="_blank"><img src="https://opencollective.com/code-surfer/sponsor/0/avatar.svg" /></a>
    <h3>{`Backers`}</h3>
    <p>{`Thank you to all our backers! 🙏 [`}<a parentName="p" {...{
        "href": "https://opencollective.com/code-surfer#backer"
      }}>{`Become a backer`}</a>{`]`}</p>
    <a href="https://opencollective.com/code-surfer#backers" target="_blank"><img src="https://opencollective.com/code-surfer/backers.svg?width=890" /></a>
    <h3>{`Contributors`}</h3>
    <p>{`This project exists thanks to all the people who contribute.`}</p>
    <img src="https://opencollective.com/code-surfer/contributors.svg?width=890&button=false" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      